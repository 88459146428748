import React, { memo, useMemo, lazy, Suspense } from 'react';
import { Loading } from 'components/shared';
import { useSelector } from 'react-redux';
import {
  LAYOUT_TYPE_CLASSIC,
  LAYOUT_TYPE_MODERN,
  LAYOUT_TYPE_SIMPLE,
  LAYOUT_TYPE_STACKED_SIDE,
  LAYOUT_TYPE_DECKED,
  LAYOUT_TYPE_BLANK,
} from 'constants/theme.constant';
import useDirection from 'utils/hooks/useDirection';
import useLocale from 'utils/hooks/useLocale';
import { useAuth } from 'contexts/Auth';
import { useLocation } from 'react-router-dom';

const layouts = {
  [LAYOUT_TYPE_CLASSIC]: lazy(() => import('./ClassicLayout')),
  [LAYOUT_TYPE_MODERN]: lazy(() => import('./ModernLayout')),
  [LAYOUT_TYPE_STACKED_SIDE]: lazy(() => import('./StackedSideLayout')),
  [LAYOUT_TYPE_SIMPLE]: lazy(() => import('./SimpleLayout')),
  [LAYOUT_TYPE_DECKED]: lazy(() => import('./DeckedLayout')),
  [LAYOUT_TYPE_BLANK]: lazy(() => import('./BlankLayout')),
};

const Layout = () => {
  const layoutType = useSelector((state) => state.theme.layout.type);
  const { pathname } = useLocation();

  const { currentUser: authenticated, loading } = useAuth();

  useDirection();
  useLocale();

  const AppLayout = useMemo(() => {
    if (authenticated) {
      return layouts[layoutType];
    }
    return lazy(() => import('./AuthLayout'));
  }, [layoutType, authenticated, pathname]);

  const loadingBlock = (
    <div className="flex flex-auto flex-col h-[100vh]">
      <Loading loading={true} />
    </div>
  );

  if (loading) {
    return loadingBlock;
  }

  return (
    <Suspense fallback={loadingBlock}>
      <AppLayout />
    </Suspense>
  );
};

export default memo(Layout);
