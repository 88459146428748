import { gql } from '@apollo/client';

export const GET_ORGANIZATIONS = gql`
  query getOrganizations {
    organizations {
      id
      name
      city
      created_at
      updated_at
      type_id
      url
      thumbnail_url
      type {
        id
        value
      }
      team {
        id
        role
        updated_at
        created_at
        user {
          created_at
          email
          firstname
          id
          last_seen
          lastname
          telephone
          updated_at
        }
      }
    }
  }
`;

export const CREATE_ORGANIZATION = gql`
  mutation createOrganization($name: String!, $city: String, $type_id: Int = 3) {
    insert_organizations(objects: { name: $name, city: $city, type_id: $type_id }) {
      returning {
        id
        name
        city
        created_at
        updated_at
        type {
          id
          value
        }
      }
    }
  }
`;

export const CREATE_ORGANIZATION_PERMISSION = gql`
  mutation createOrganizationPermission(
    $organization_id: Int!
    $user_id: Int!
    $role: String! = "admin"
  ) {
    insert_organizations_permissions(
      objects: { organization_id: $organization_id, user_id: $user_id, role: $role }
    ) {
      returning {
        id
        organization_id
        user_id
        role
      }
    }
  }
`;

export const GET_ORGANIZATIONS_TYPES = gql`
  query getOrganizationsTypes {
    organizations_types {
      type_id
      id
      value
    }
  }
`;
