import { createServer } from 'miragejs';
import appConfig from 'configs/app.config';

import { signInUserData } from './data/authData';
import { notificationListData, searchQueryPoolData } from './data/commonData';
import { applicationsListData } from './data/applicationsData';
import {
  projectList,
  scrumboardData,
  issueData,
  projectDashboardData,
} from './data/projectData';
import {
  authFakeApi,
  commonFakeApi,
  applicationsFakeApi,
  projectFakeApi,
} from './fakeApi';

const { apiPrefix } = appConfig;

export default function mockServer({ environment = 'test' }) {
  return createServer({
    environment,
    seeds(server) {
      server.db.loadData({
        signInUserData,
        notificationListData,
        searchQueryPoolData,
        applicationsListData,
        projectList,
        scrumboardData,
        issueData,
        projectDashboardData,
      });
    },
    routes() {
      this.urlPrefix = '';
      this.namespace = '';
      this.passthrough((request) => {
        let isExternal = request.url.startsWith('http');
        return isExternal;
      });
      this.passthrough();

      authFakeApi(this, apiPrefix);
      commonFakeApi(this, apiPrefix);
      applicationsFakeApi(this, apiPrefix);
      projectFakeApi(this, apiPrefix);
    },
  });
}
