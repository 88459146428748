import React, { useMemo, useContext, useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { GET_ORGANIZATIONS } from './organizations.gql';
import { useAuth } from 'contexts/Auth';

const OrganizationsContext = React.createContext();

export function useOrganizations() {
  return useContext(OrganizationsContext);
}

export default function OrganizationsProvider({ children }) {
  const { authenticated } = useAuth();

  const { loading, data } = useQuery(GET_ORGANIZATIONS, {
    skip: !authenticated,
  });

  const organizations = useMemo(() => {
    if (data) {
      return data?.organizations?.slice().sort((a, b) => a.id - b.id) || [];
    }
    return [];
  }, [data]);

  const [selectedOrganization, setSelectedOrganization] = useState(null);

  useEffect(() => {
    if (!selectedOrganization && organizations?.length > 0) {
      const selected = localStorage.getItem('frontpilot_organization_id');
      if (selected) {
        setSelectedOrganization(organizations.find((org) => org.id === Number(selected)));
      } else {
        setSelectedOrganization(organizations[0]);
      }
    }
  }, [selectedOrganization, organizations]);

  const handleSelectOrganization = (organization) => {
    localStorage.setItem('frontpilot_organization_id', organization.id);
    localStorage.removeItem('frontpilot_project_id');
    setSelectedOrganization(organization);
  };

  const value = {
    loading,
    selectedOrganization,
    organizations,
    handleSelectOrganization,
  };

  return (
    <OrganizationsContext.Provider value={value}>
      {children}
    </OrganizationsContext.Provider>
  );
}
