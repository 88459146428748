export const signInUserData = [
  {
    id: '21',
    avatar: '/img/avatars/thumb-1.jpg',
    userName: 'admin',
    email: 'wesley@castleit.io',
    authority: ['admin', 'user'],
    password: '123Qwe1',
    accountUserName: 'admin',
  },
];
