import { gql } from '@apollo/client';

export const DesignFragment = gql`
  fragment DesignFragment on design {
    id
    created_at
    updated_at
    project_id
    border_color
    border_size
    button_border_radius
    button_text_transform
    color_black
    color_brand_dark
    color_brand_darken
    color_brand_light
    color_brand_lighten
    color_brand_medium
    color_brand_primary
    color_brand_secondary
    color_error
    color_gray_base
    color_gray_dark
    color_gray_darken
    color_gray_light
    color_gray_lighten
    color_gray_medium
    color_info
    color_success
    color_warning
    color_white
    font_family_body
    font_family_heading
    font_size_body
    font_size_h1
    font_size_h2
    font_size_h3
    font_size_h4
    font_size_h5
    font_size_h6
    font_weight_h1
    font_weight_h2
    font_weight_h3
    font_weight_h4
    font_weight_h5
    font_weight_h6
    box_shadow
    body_background
    body_text_color
    body_container
    carousel_enable_arrows_desktop
    carousel_enable_arrows_mobile
    carousel_enable_arrows_tablet
    carousel_enable_dots_desktop
    carousel_enable_dots_mobile
    carousel_enable_dots_tablet
    carousel_items_desktop
    carousel_items_mobile
    carousel_items_tablet
    footer_container
    footer_behance_link
    footer_company_block_id
    footer_dribbble_link
    footer_enable_newsletter
    footer_enable_payment_icons
    footer_enable_social_links
    footer_facebook_link
    footer_github_link
    footer_googleplus_link
    footer_instagram_link
    footer_layout_desktop
    footer_layout_mobile
    footer_linkedin_link
    footer_links_1_block_id
    footer_links_2_block_id
    footer_links_3_block_id
    footer_links_4_block_id
    footer_links_5_block_id
    footer_payment_americanexpress_icon
    footer_payment_amex_icon
    footer_payment_applepay_icon
    footer_payment_bitcoin_icon
    footer_payment_dinersclub_icon
    footer_payment_discover_icon
    footer_payment_elo_icon
    footer_payment_googlepay_icon
    footer_payment_jcb_icon
    footer_payment_hipercard_icon
    footer_payment_maestro_icon
    footer_payment_mastercard_icon
    footer_payment_paypal_icon
    footer_payment_pix_icon
    footer_payment_boleto_icon
    footer_payment_visa_icon
    footer_pinterest_link
    footer_tiktok_link
    footer_twitter_link
    footer_vimeo_link
    footer_youtube_link
    footer_background_base
    footer_background_bottom
    footer_background_newsletter
    footer_text_base
    footer_text_bottom
    footer_text_newsletter
    footer_links_color
    footer_links_color_hover
    footer_border_color
    header_container
    header_layout_desktop
    header_layout_mobile
    header_links_block_id
    header_logo_desktop
    header_logo_mobile
    header_menu_block_id
    header_welcome_block_id
    header_show_account_desktop
    header_show_account_mobile
    header_show_search_desktop
    header_show_search_mobile
    header_show_wishlist_desktop
    header_show_wishlist_mobile
    header_show_cart_desktop
    header_show_cart_mobile
    header_background_base
    header_background_top
    header_background_menu
    header_text_base
    header_text_top
    header_text_menu
    header_border_color
    header_count_background
    header_count_text
    image_aspect_ratio
    input_border_radius
    input_type
    link_color
    link_color_hover
    product_card_alignment
    product_card_button_type
    product_card_enable_add_to_cart_desktop
    product_card_enable_add_to_cart_mobile
    product_card_enable_category
    product_card_enable_installments
    product_card_enable_rating
    product_card_enable_short_description
    product_card_enable_swatch
    product_card_enable_wishlist
    product_card_image_hover_flip
    product_card_name_size
    product_card_name_weight
    product_card_name_text_transform
    product_card_name_color
    product_card_name_color_hover
    product_card_image_aspect_ratio
    text_color_body
    text_color_body_light
    text_color_h1
    text_color_h2
    text_color_h3
    text_color_h4
    text_color_h5
    text_color_h6
    text_transform_h1
    text_transform_h2
    text_transform_h3
    text_transform_h4
    text_transform_h5
    text_transform_h6
    border_radius
    logo_src
    header_box_shadow
    megamenu_box_shadow
    megamenu_columns
    megamenu_text_transform
    megamenu_font_size
    newsletter_block_id
  }
`;

export const PagesFragment = gql`
  fragment PagesFragment on pages {
    id
    created_at
    updated_at
    project_id
    cart_container
    cart_enable_back_to_shopping
    cart_enable_coupon
    cart_enable_crosssell
    cart_enable_estimate_shipping
    cart_enable_gift_options
    cart_enable_gift_wrapping
    cart_enable_payment_icons
    cart_layout_desktop
    cart_layout_mobile
    catalog_body_container
    catalog_body_layout_desktop
    catalog_body_layout_mobile
    catalog_filters_category
    catalog_filters_price
    catalog_heading_container
    catalog_heading_layout_desktop
    catalog_heading_layout_mobile
    catalog_product_grid_desktop
    catalog_product_grid_mobile
    checkout_container
    checkout_enable_back_to_cart
    checkout_enable_coupon
    checkout_enable_gift_options
    checkout_enable_gift_wrapping
    checkout_enable_payment_icons
    checkout_enable_reduced_page
    checkout_layout_desktop
    checkout_layout_mobile
    product_actions_quantity_mobile
    product_actions_quantity_desktop
    product_border_radius_desktop
    product_border_radius_mobile
    product_details_position_desktop
    product_details_position_mobile
    product_enable_attributes
    product_enable_rating
    product_enable_recommendations
    product_enable_related_products
    product_enable_reviews
    product_enable_upsell_products
    product_gallery_desktop
    product_gallery_mobile
    product_hidden_empty_rating
    product_only_left_stock_quantity_desktop
    product_only_left_stock_quantity_mobile
    product_show_only_left_stock_desktop
    product_show_only_left_stock_mobile
    product_show_stock_quantity_desktop
    product_show_stock_quantity_mobile
    product_stick_buy_mobile
    product_stick_buy_desktop
    product_stock_show_outofstock_desktop
    product_stock_show_outofstock_mobile
    product_swatch_type_desktop
    product_swatch_type_mobile
    product_video_autoplay_desktop
    product_video_autoplay_mobile
  }
`;

export const SettingsFragment = gql`
  fragment SettingsFragment on settings {
    currency
    domain_production_url
    domain_staging_url
    domain_temporary_url
    domain_local_url
    id
    installments_enable
    installments_fees
    installments_max_quantity
    installments_min_value
    languages
    magento_api_access_token
    magento_api_access_token_secret
    magento_backend_url
    magento_staging_backend_url
    magento_production_backend_url
    project_id
    pwa_app_description
    pwa_app_name
    pwa_app_short_name
    pwa_background_color
    pwa_icon
    pwa_theme_color
    store_view_code
    use_store_code_in_url
  }
`;

export const ProjectFragment = gql`
  fragment ProjectFragment on projects {
    id
    uid
    name
    slug
    status
    setup_status
    repository_git
    organization_id
    onboarding
    type
    parent_uid
    thumbnail_url
    design {
      ...DesignFragment
    }
    pages {
      ...PagesFragment
    }
    settings {
      ...SettingsFragment
    }
    organization {
      id
      name
      thumbnail_url
    }
  }
  ${DesignFragment}
  ${PagesFragment}
  ${SettingsFragment}
`;

export const GET_PROJECT_BY_ID = gql`
  subscription getProjectById($id: Int!) {
    projects(where: { id: { _eq: $id } }) {
      ...ProjectFragment
    }
  }
  ${ProjectFragment}
`;

export const GET_PROJECTS = gql`
  query getProjects {
    projects {
      ...ProjectFragment
    }
  }
  ${ProjectFragment}
`;

export const CREATE_PROJECT = gql`
  mutation createProject($data: CreateProjectInput!) {
    createProject(input: $data) {
      id
      uid
      name
      slug
      setup_status
      repository_git
    }
  }
`;

export const UPDATE_PROJECT = gql`
  mutation updateProject($id: Int!, $data: projects_set_input!) {
    update_projects_by_pk(pk_columns: { id: $id }, _set: $data) {
      ...ProjectFragment
    }
  }
  ${ProjectFragment}
`;

export const UPDATE_DESIGN = gql`
  mutation updateDesign($id: Int!, $data: design_set_input!) {
    update_design(where: { id: { _eq: $id } }, _set: $data) {
      affected_rows
    }
  }
`;

export const UPDATE_PAGES = gql`
  mutation updatePages($id: Int!, $data: pages_set_input!) {
    update_pages(where: { id: { _eq: $id } }, _set: $data) {
      affected_rows
    }
  }
`;

export const UPDATE_SETTINGS = gql`
  mutation updateSettings($id: Int!, $data: settings_set_input!) {
    update_settings(where: { id: { _eq: $id } }, _set: $data) {
      affected_rows
    }
  }
`;

export const MODULES_CONFIG = gql`
  query modulesConfig($project_id: Int!) {
    module_amasty_automatic_related_products(
      where: { project_id: { _eq: $project_id } }
    ) {
      setup_status
      id
      project_id
    }

    module_amasty_product_labels(where: { project_id: { _eq: $project_id } }) {
      setup_status
      id
      project_id
    }

    module_sample(where: { project_id: { _eq: $project_id } }) {
      setup_status
      id
      project_id
    }

    module_asaas(where: { project_id: { _eq: $project_id } }) {
      setup_status
      id
      project_id
    }

    module_stripe(where: { project_id: { _eq: $project_id } }) {
      setup_status
      id
      project_id
    }

    module_gtm(where: { project_id: { _eq: $project_id } }) {
      setup_status
      id
      project_id
    }
  }
`;
