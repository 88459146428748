import React from 'react';
import { Card, Tag, toast, Notification } from 'components/ui';
import { useProjects } from 'contexts/Projects';
import { useOrganizations } from 'contexts/Organizations';
import { useNavigate } from 'react-router-dom';

const ProjectCard = ({ project }) => {
  const navigate = useNavigate();
  const { currentProjectId, handleSelectProject } = useProjects();
  const { organizations, handleSelectOrganization } = useOrganizations();

  return (
    <Card
      clickable
      className={`hover:border-purple-500 transition duration-150 ease-in-out dark:border dark:hover:border-purple-400 dark:border-solid${
        project?.id === currentProjectId
          ? ' border-purple-500 bg-purple-50 dark:border-purple-400 dark:bg-gray-700'
          : ''
      }`}
      headerClass="p-0"
      footerBorder={false}
      headerBorder={false}
      onClick={() => {
        handleSelectOrganization(
          organizations.find(({ id }) => id === project?.organization?.id)
        );
        handleSelectProject(project.id);
        navigate('/store/overview');
        toast.push(
          <Notification
            title={`${project.name} selected!`}
            type="success"
            duration={6000}
            closable
          ></Notification>,
          {
            placement: 'bottom-end',
          }
        );
      }}
    >
      <div className="flex items-start gap-4">
        <figure>
          <img
            className="rounded"
            src={
              project.thumbnail_url ||
              'https://res.cloudinary.com/dxybmtoxz/image/upload/v1718246876/magepwa/modules/thumbnail/wuxkv29ggiyen4w4ahkb.png'
            }
            width={72}
          />
        </figure>

        <div>
          <span className="text-purple-600 dark:text-purple-100 font-semibold text-xs">
            {project?.organization?.name}
          </span>
          <h4 className="font-bold text-lg">{project?.name}</h4>
          <p>
            {project?.settings?.domain_staging_url && (
              <span className="text-xs">
                <a
                  href={project?.settings?.domain_staging_url}
                  target="_blank"
                  className="hover:text-black dark:hover:text-white transition duration-150 ease-in-out"
                >
                  {project?.settings?.domain_staging_url}
                </a>
              </span>
            )}
          </p>
        </div>

        <div className="flex items-center gap-4 ml-auto">
          {project.organization.name.includes('Boost') ||
          project.organization.name.includes('Cammino') ||
          project.organization.name.includes('JPP') ||
          project.organization.name.includes('Castle') ? (
            <Tag className="bg-purple-100 text-purple-600 dark:bg-purple-500/20 dark:text-purple-100 border-0 rounded">
              PRO
            </Tag>
          ) : (
            <Tag className="bg-gray-100 text-gray-600 dark:bg-gray-500/20 dark:text-gray-100 border-0 rounded">
              FREE
            </Tag>
          )}
        </div>
      </div>
    </Card>
  );
};

export default ProjectCard;
