import { gql } from '@apollo/client';

export const SIGN_IN = gql`
  mutation signIn($input: SignInInput!) {
    signIn(input: $input) {
      access_token
      error
    }
  }
`;

export const SIGN_UP = gql`
  mutation signUp($input: SignUpInput!) {
    signUp(input: $input) {
      access_token
      error
    }
  }
`;

export const GET_USER = gql`
  query getUser {
    users {
      id
      firstname
      lastname
      email
      telephone
      firebase_uid
    }
  }
`;

export const FORGOT_PASSOWRD = gql`
  mutation forgotPassword($email: String!) {
    forgotPassword(email: $email) {
      error
      success
    }
  }
`;
