import React, { useContext, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { GET_STORE_CONFIG, GET_MAGENTO } from './magento.gql';
import { useProjects } from 'contexts/Projects';
import { useSelector } from 'react-redux';

const MagentoContext = React.createContext();

export function useMagento() {
  return useContext(MagentoContext);
}

export default function MagentoProvider({ children }) {
  const { currentProjectId } = useProjects();
  const { signedIn } = useSelector((state) => state.auth.session);

  const { data, loading, error } = useQuery(GET_STORE_CONFIG, {
    skip: !signedIn,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    context: {
      clientName: 'magento',
    },
  });

  const { data: magentoData, error: errorMagento } = useQuery(GET_MAGENTO, {
    skip: !signedIn,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    context: {
      clientName: 'magento',
    },
  });

  const magento = useMemo(() => {
    return magentoData?.headless;
  }, [magentoData]);

  const preview = useMemo(() => {
    if (magentoData?.categories?.items?.length > 0) {
      const category =
        magentoData.categories.items[0].children?.length > 0
          ? magentoData.categories.items[0].children[0]
          : null;
      const { items } = magentoData.categories.items[0].products;
      const product = items?.length > 0 ? items[0] : null;

      return {
        category,
        product,
      };
    }
  }, [magentoData]);

  const availableStores = useMemo(() => {
    if (data?.storeConfig?.availableStores) {
      return data.storeConfig.availableStores;
    }
  }, [data]);

  const storeConfig = useMemo(() => {
    if (data?.storeConfig) {
      return data.storeConfig;
    }
  }, [data]);

  const value = {
    preview,
    error: errorMagento,
    magento,
    availableStores,
    storeConfig,
  };

  return (
    <MagentoContext.Provider value={value}>
      {!loading && children}
    </MagentoContext.Provider>
  );
}
