import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyBgg-olKSbeRruVENezmo8vbPPdmR2kmwA',
  authDomain: 'Frontpilot-ef75b.firebaseapp.com',
  projectId: 'Frontpilot-ef75b',
  storageBucket: 'Frontpilot-ef75b.appspot.com',
  messagingSenderId: '749602561680',
  appId: '1:749602561680:web:d88bbe034f3cb788064215',
  measurementId: 'G-CFTJHMEKGP',
};

const app = firebase.initializeApp(firebaseConfig);

export const auth = app.auth();
export default app;
