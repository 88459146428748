export const applicationsListData = {
  adobe: [
    {
      name: 'B2B Commerce',
      desc: 'Upload your files to Google Drive',
      img: '/img/thumbs/adobe.png',
      type: 'Cloud storage',
      active: false,
      available: true,
      compatibility: ['ac'],
    },
    {
      name: 'Reward Points',
      desc: 'Post to a Slack channel',
      img: '/img/thumbs/adobe.png',
      type: 'Notifications and events',
      active: false,
      available: true,
      compatibility: ['ac'],
    },
    {
      name: 'Adobe Sensei',
      desc: 'Retrieve notion note to your project',
      img: '/img/thumbs/adobe.png',
      type: 'Content management',
      active: false,
      available: false,
      compatibility: ['ac'],
    },
    {
      name: 'Adobe Launch',
      desc: 'Create Jira issues',
      img: '/img/thumbs/adobe-launch.png',
      type: 'Project management',
      active: false,
      compatibility: ['ac'],
    },
    {
      name: 'Adobe Target',
      desc: 'Exchange data with Zendesk',
      img: '/img/thumbs/adobe-target.png',
      type: 'Customer service',
      active: false,
      available: false,
      compatibility: ['ac'],
    },
  ],
  performance: [
    {
      name: 'Sample',
      desc: 'Exemple module for testing automatic setup',
      img: '/img/thumbs/magento.png',
      type: 'Utilities',
      active: false,
      available: true,
      compatibility: ['ac', 'ce'],
    },
    {
      name: 'CDN Cache',
      desc: 'Exchange files with a GitHub repository',
      img: '/img/thumbs/magento.png',
      type: 'Code repositories',
      active: false,
      available: false,
      compatibility: ['ac', 'ce'],
    },
    {
      name: 'AMP',
      desc: 'Exchange files with a GitHub repository',
      img: '/img/thumbs/magento.png',
      type: 'Code repositories',
      active: false,
      available: false,
      compatibility: ['ac', 'ce'],
    },
  ],
  analytics: [
    {
      name: 'Google Analytics',
      desc: 'Exchange data with Dropbox',
      img: '/img/thumbs/google-analytics.png',
      type: 'Cloud storage',
      active: true,
      available: true,
      compatibility: ['ac', 'ce'],
    },
    {
      name: 'Hotjar',
      desc: 'Exchange files with a GitHub repository',
      img: '/img/thumbs/hotjar.png',
      type: 'Code repositories',
      active: true,
      available: false,
      compatibility: ['ac', 'ce'],
    },
  ],
  marketing: [
    {
      name: 'Google Tag Manager',
      desc: 'Exchange data with Dropbox',
      img: '/img/thumbs/google-tag-manager.png',
      type: 'Cloud storage',
      active: false,
      available: false,
      compatibility: ['ac', 'ce'],
    },
    {
      name: 'Facebook Business',
      desc: 'Exchange files with a GitHub repository',
      img: '/img/thumbs/facebook.png',
      type: 'Code repositories',
      active: false,
      available: false,
      compatibility: ['ac', 'ce'],
    },
    {
      name: 'Hubspot',
      desc: 'Exchange files with a GitHub repository',
      img: '/img/thumbs/hubspot.png',
      type: 'Code repositories',
      active: false,
      available: false,
      compatibility: ['ac', 'ce'],
    },
    {
      name: 'Dito CRM',
      desc: 'Exchange files with a GitHub repository',
      img: '/img/thumbs/dito.png',
      type: 'Code repositories',
      active: false,
      available: false,
      compatibility: ['ac', 'ce'],
    },
  ],
  payment: [
    {
      name: 'MercadoPago',
      desc: 'Exchange files with a GitHub repository',
      img: '/img/thumbs/mercadopago.png',
      type: 'Code repositories',
      active: false,
      available: false,
      compatibility: ['ac', 'ce'],
    },
    {
      name: 'PayPal',
      desc: 'Exchange files with a GitHub repository',
      img: '/img/thumbs/paypal.png',
      type: 'Code repositories',
      active: false,
      available: false,
      compatibility: ['ac', 'ce'],
    },
    {
      name: 'U4C',
      desc: 'Exchange files with a GitHub repository',
      img: '/img/thumbs/u4c.png',
      type: 'Code repositories',
      active: false,
      available: false,
      compatibility: ['ac', 'ce'],
    },
  ],
  shipping: [
    {
      name: 'Frenet',
      desc: 'Exchange files with a GitHub repository',
      img: '/img/thumbs/frenet.png',
      type: 'Code repositories',
      active: false,
      available: false,
      compatibility: ['ac', 'ce'],
    },
    {
      name: 'Melhor Envio',
      desc: 'Exchange files with a GitHub repository',
      img: '/img/thumbs/melhor-envio.png',
      type: 'Code repositories',
      active: false,
      available: false,
      compatibility: ['ac', 'ce'],
    },
  ],
  customer: [
    {
      name: 'One Step Checkout',
      desc: 'Exchange files with a GitHub repository',
      img: '/img/thumbs/magento.png',
      type: 'Code repositories',
      active: false,
      available: false,
      compatibility: ['ac', 'ce'],
    },
    {
      name: 'Adobe Gift Card',
      desc: 'Exchange files with a GitHub repository',
      img: '/img/thumbs/adobe.png',
      type: 'Code repositories',
      active: false,
      available: false,
      compatibility: ['ac'],
    },
    {
      name: 'Amasty Product Labels',
      desc: 'Exchange files with a GitHub repository',
      img: '/img/thumbs/amasty.png',
      type: 'Code repositories',
      active: false,
      available: false,
      compatibility: ['ac', 'ce'],
    },
    {
      name: 'Amasty Automatic Related',
      desc: 'Exchange files with a GitHub repository',
      img: '/img/thumbs/amasty.png',
      type: 'Code repositories',
      active: false,
      available: false,
      compatibility: ['ac', 'ce'],
    },
  ],
  loyalty: [
    {
      name: 'Adobe Rewards Points',
      desc: 'Exchange files with a GitHub repository',
      img: '/img/thumbs/adobe.png',
      type: 'Code repositories',
      active: false,
      available: false,
      compatibility: ['ac'],
    },
    {
      name: 'Fidelimax',
      desc: 'Exchange files with a GitHub repository',
      img: '/img/thumbs/fidelimax.png',
      type: 'Code repositories',
      active: false,
      available: false,
      compatibility: ['ac', 'ce'],
    },
    {
      name: 'Open Loyalty',
      desc: 'Exchange files with a GitHub repository',
      img: '/img/thumbs/open-loyalty.png',
      type: 'Code repositories',
      active: false,
      available: false,
      compatibility: ['ac', 'ce'],
    },
  ],
  recommendations: [
    {
      name: 'Adobe Sensei',
      desc: 'Exchange files with a GitHub repository',
      img: '/img/thumbs/adobe.png',
      type: 'Code repositories',
      active: false,
      available: false,
      compatibility: ['ac'],
    },
    {
      name: 'Linx Impulse',
      desc: 'Exchange files with a GitHub repository',
      img: '/img/thumbs/linx.png',
      type: 'Code repositories',
      active: false,
      available: false,
      compatibility: ['ac', 'ce'],
    },
    {
      name: 'Algolia',
      desc: 'Exchange files with a GitHub repository',
      img: '/img/thumbs/algolia.png',
      type: 'Code repositories',
      active: false,
      available: false,
      compatibility: ['ac', 'ce'],
    },
  ],
  marketplace: [
    {
      name: 'Webkul Multi Vendor',
      desc: 'Exchange files with a GitHub repository',
      img: '/img/thumbs/webkul.png',
      type: 'Code repositories',
      active: false,
      available: false,
      compatibility: ['ac', 'ce'],
    },
  ],
};
