import 'firebase/firestore';
import { auth } from 'services/firebase';
import React, { useContext, useState, useEffect } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { GET_USER, SIGN_UP, SIGN_IN, FORGOT_PASSOWRD } from './auth.gql';
import { useDispatch } from 'react-redux';
import { onSignInSuccess, onSignOutSuccess } from 'store/auth/sessionSlice';
import { REDIRECT_URL_KEY } from 'constants/app.constant';
import { useNavigate } from 'react-router-dom';
import useQueryParams from 'utils/hooks/useQuery';
import appConfig from 'configs/app.config';

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export default function AuthProvider({ children }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const query = useQueryParams();

  const [currentUser, setCurrentUser] = useState();

  const { data, loading, error } = useQuery(GET_USER, {
    skip: !localStorage.getItem('token'),
  });
  const [signInMutation] = useMutation(SIGN_IN);
  const [signUpMutation] = useMutation(SIGN_UP);
  const [forgotPasswordMutation] = useMutation(FORGOT_PASSOWRD);

  const configToken = (token) => {
    if (!token) return;
    dispatch(onSignInSuccess(token));
    localStorage.setItem('token', token);
  };

  const signUp = async ({ email, password, firstname, lastname, telephone }) => {
    try {
      const res = await signUpMutation({
        contextName: 'notLogged',
        variables: {
          input: {
            email,
            password,
            firstname,
            lastname,
            telephone,
          },
        },
      });

      if (res?.data?.signUp?.error) {
        return res?.data?.signUp;
      }

      configToken(res?.data?.signUp?.access_token);
    } catch (error) {
      dispatch(onSignOutSuccess());
      localStorage.removeItem('token');
      return error;
    }
  };

  const signIn = async ({ email, password }) => {
    try {
      const res = await signInMutation({
        contextName: 'notLogged',
        variables: {
          input: {
            email,
            password,
          },
        },
      });

      if (res?.data?.signIn?.error) {
        return res?.data?.signIn;
      }

      configToken(res?.data?.signIn?.access_token);
    } catch (error) {
      dispatch(onSignOutSuccess());
      localStorage.removeItem('token');
      return error;
    }
  };

  const signOut = async () => {
    await auth?.signOut();
    dispatch(onSignOutSuccess());
    setCurrentUser(null);
    localStorage.removeItem('token');
  };

  const handleForgotPassword = async ({ email }) => {
    try {
      const res = await forgotPasswordMutation({
        contextName: 'notLogged',
        variables: {
          email,
        },
      });
      return res?.data?.forgotPassword;
    } catch (error) {
      return error;
    }
  };

  function resetPassword(email) {
    return auth.sendPasswordResetEmail(email);
  }

  function updateEmail(email) {
    return currentUser.updateEmail(email);
  }

  function updatePassword(password) {
    return currentUser.updatePassword(password);
  }

  useEffect(() => {
    if (data?.users?.length) {
      const user = data.users[0];
      setCurrentUser({
        ...user,
        authority: ['USER'],
        userName: `${user.firstname} ${user.lastname}`,
        avatar: '',
      });

      // const redirectUrl = query.get(REDIRECT_URL_KEY);
      // navigate(redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath);
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      dispatch(onSignOutSuccess());
      localStorage.removeItem('token');
    }
  }, [error]);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        const { refreshToken } = user;
        try {
          localStorage.setItem('token', refreshToken);
        } catch (error) {
          return error;
        }
      }
    });

    return unsubscribe;
  }, []);

  const value = {
    loading,
    authenticated: !!currentUser,
    currentUser,
    signIn,
    signUp,
    signOut,
    handleForgotPassword,
    resetPassword,
    updateEmail,
    updatePassword,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
