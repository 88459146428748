import React, { useContext, useState, useEffect, useMemo } from 'react';
import { useQuery, useMutation, useSubscription } from '@apollo/client';
import {
  GET_PROJECT_BY_ID,
  GET_PROJECTS,
  UPDATE_PROJECT,
  UPDATE_DESIGN,
  UPDATE_PAGES,
  UPDATE_SETTINGS,
  MODULES_CONFIG,
} from './projects.gql';
import { useNavigate } from 'react-router-dom';
import { useOrganizations } from 'contexts/Organizations';
import { useAuth } from 'contexts/Auth';

const ProjectsContext = React.createContext();

export function useProjects() {
  return useContext(ProjectsContext);
}

export default function ProjectsProvider({ children }) {
  const navigate = useNavigate();
  const { selectedOrganization } = useOrganizations();
  const { authenticated } = useAuth();

  const { data, loading } = useQuery(GET_PROJECTS, {
    skip: !authenticated,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });

  const [updateProject, { loading: updateProjectLoading }] = useMutation(UPDATE_PROJECT);
  const [updateDesign, { loading: updateDesignLoading }] = useMutation(UPDATE_DESIGN);
  const [updatePages, { loading: updatePagesLoading }] = useMutation(UPDATE_PAGES);
  const [updateSettings, { loading: updateSettingsLoading }] =
    useMutation(UPDATE_SETTINGS);

  const localOrgId = localStorage.getItem('frontpilot_organization_id');
  const org_id = selectedOrganization?.id || localOrgId;
  const projects =
    data?.projects
      ?.filter((p) => p.organization_id === org_id)
      .sort((a, b) => a.id - b.id) || [];
  const allProjects = data?.projects || [];

  const localProjectId = localStorage.getItem('frontpilot_project_id');
  const initId = localProjectId ? Number(localProjectId) : null;
  const [currentProjectId, setCurrentProjectId] = useState(initId);
  const [currentProject, setCurrentProject] = useState(null);
  const [updating, setUpdating] = useState(false);

  const { data: dataCurrentProject, error } = useSubscription(GET_PROJECT_BY_ID, {
    skip: !currentProjectId,
    variables: {
      id: currentProjectId,
    },
  });

  useEffect(() => {
    if (error) {
      localStorage.removeItem('frontpilot_project_id');
      window.location.reload();
    }
  }, [error]);

  const [localChanges, setLocalChanges] = useState({});
  const [device, setDevice] = useState('desktop');

  const handleSelectProject = (id) => {
    console.log({id});
    setUpdating(true);

    if (!id) {
      navigate('/projects/create');
      return;
    }

    setCurrentProjectId(id);
    setCurrentProject(projects.find((p) => p.id === id));
    localStorage.setItem('frontpilot_project_id', id);
  };

  // change organization and set first project
  useEffect(() => {
    if (!localProjectId && selectedOrganization && projects?.length) {
      const firstOrganizationProject = projects?.find(
        (project) => project.organization_id === selectedOrganization.id
      );

      if (
        !projects.find((p) => p.id === currentProject?.id) &&
        firstOrganizationProject
      ) {
        setCurrentProject(firstOrganizationProject);
        setCurrentProjectId(firstOrganizationProject.id);
        localStorage.setItem('frontpilot_project_id', firstOrganizationProject.id);
      }
    }
  }, [currentProject, selectedOrganization, projects]);

  // set project state from data query
  useEffect(() => {
    if (dataCurrentProject?.projects?.length > 0) {
      const project = dataCurrentProject?.projects[0] || {};
      setCurrentProject(project);
      setUpdating(false);
    }
  }, [dataCurrentProject]);

  const handleUpdateProject = async (data) => {
    try {
      await updateProject({
        variables: {
          id: currentProjectId,
          data,
        },
      });
    } catch (error) {}
  };

  const handleUpdateDesign = async (data) => {
    try {
      await updateDesign({
        variables: {
          id: currentProject?.design?.id,
          data,
        },
        refetchQueries: [
          {
            query: GET_PROJECTS,
          },
        ],
        awaitRefetchQueries: false,
      });
    } catch (error) {}
  };

  const handleUpdatePages = async (data) => {
    try {
      await updatePages({
        variables: {
          id: currentProject?.pages?.id,
          data,
        },
        refetchQueries: [
          {
            query: GET_PROJECTS,
          },
        ],
      });
    } catch (error) {}
  };

  const handleLocalChanges = (key, values) => {
    setLocalChanges((prevState) => ({
      ...prevState,
      [key]: {
        ...prevState[key],
        ...values,
      },
    }));
  };

  const parentProject = currentProject?.parent_uid
    ? projects.find((p) => p.uid === currentProject?.parent_uid)
    : null;
  const useStoreUrl = parentProject?.settings?.use_store_code_in_url || 3;
  const moduleConfigProject = useStoreUrl !== 3 ? parentProject : currentProject;

  const { data: modulesConfig } = useQuery(MODULES_CONFIG, {
    skip: !moduleConfigProject,
    variables: {
      project_id: moduleConfigProject?.id,
    },
  });

  const currentModules = useMemo(() => {
    if (modulesConfig) {
      let modules = [];
      const {
        module_sample,
        module_amasty_automatic_related_products,
        module_amasty_product_labels,
        module_asaas,
        module_stripe,
        module_gtm,
      } = modulesConfig;
      if (module_sample?.length) {
        modules = [
          ...modules,
          {
            ...module_sample[0],
            module_code: 'sample',
            module_name: 'Sample',
          },
        ];
      }

      if (module_amasty_automatic_related_products?.length) {
        modules = [
          ...modules,
          {
            ...module_amasty_automatic_related_products[0],
            module_code: 'amasty-automatic-related-products',
            module_name: 'Amasty Automatic Related Products',
          },
        ];
      }

      if (module_amasty_product_labels?.length) {
        modules = [
          ...modules,
          {
            ...module_amasty_product_labels[0],
            module_code: 'amasty-product-labels',
            module_name: 'Amasty Product Labels',
          },
        ];
      }

      if (module_asaas?.length) {
        modules = [
          ...modules,
          {
            ...module_asaas[0],
            module_code: 'asaas',
            module_name: 'Asaas',
          },
        ];
      }

      if (module_stripe?.length) {
        modules = [
          ...modules,
          {
            ...module_stripe[0],
            module_code: 'stripe',
            module_name: 'Stripe',
          },
        ];
      }

      if (module_gtm?.length) {
        modules = [
          ...modules,
          {
            ...module_gtm[0],
            module_code: 'gtm',
            module_name: 'Google Tag Manager',
          },
        ];
      }

      return modules;
    }

    return [];
  }, [modulesConfig]);

  const saveButtonLoading =
    loading ||
    updateProjectLoading ||
    updateDesignLoading ||
    updatePagesLoading ||
    updateSettingsLoading;

  const value = {
    parentProject,
    moduleConfigProject,
    currentProjectId,
    currentProject,
    projects,
    allProjects,
    loading,
    saveButtonLoading,
    localChanges,
    device,
    handleSelectProject,
    handleUpdateDesign,
    handleUpdatePages,
    handleLocalChanges,
    setDevice,
    currentModules,
    updating,
  };

  return <ProjectsContext.Provider value={value}>{children}</ProjectsContext.Provider>;
}
