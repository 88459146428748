import React, { useState, useMemo, useEffect } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import store, { persistor } from './store';
import Theme from 'components/template/Theme';
import Layout from 'components/layout';
import mockServer from './mock';
import appConfig from 'configs/app.config';
import './locales';
import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  HttpLink,
  split,
  concat,
} from '@apollo/client';
import AuthProvider from 'contexts/Auth';
import ProjectsProvider from 'contexts/Projects';
import OrganizationsProvider from 'contexts/Organizations';
import MagentoProvider from 'contexts/Magento';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { createClient } from 'graphql-ws';
import { useSelector } from 'react-redux';

const environment = process.env.NODE_ENV;

/**
 * Set enableMock(Default false) to true at configs/app.config.js
 * If you wish to enable mock api
 */
if (appConfig.enableMock) {
  mockServer({ environment });
}

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ClientContainer>
          <BrowserRouter>
            <AuthProvider>
              <Theme>
                <OrganizationsProvider>
                  <ProjectsProvider>
                    <MagentoProvider>
                      <Layout />
                    </MagentoProvider>
                  </ProjectsProvider>
                </OrganizationsProvider>
              </Theme>
            </AuthProvider>
          </BrowserRouter>
        </ClientContainer>
      </PersistGate>
    </Provider>
  );
}

export const ClientContainer = ({ children }) => {
  const uri = 'https://magento-headless.hasura.app/v1/graphql';
  const { token, signedIn } = useSelector((state) => state.auth.session);

  const client = useMemo(() => {
    let headers = {};
    if (token) {
      headers.Authorization = `Bearer ${token}`;
    } else {
      headers['X-Hasura-Role'] = 'anonymous';
    }

    const httpLink = new HttpLink({ uri });
    const magentoLink = new HttpLink({
      uri: 'https://magento.castleit.io/graphql',
    });
    const wsLink = new GraphQLWsLink(
      createClient({
        url: 'wss://magento-headless.hasura.app/v1/graphql',
        connectionParams: {
          headers,
        },
      })
    );

    const splitLink = split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return (
          definition.kind === 'OperationDefinition' &&
          definition.operation === 'subscription'
        );
      },
      wsLink,
      httpLink
    );

    const authMiddleware = new ApolloLink((operation, forward) => {
      operation.setContext(({ headers: ctxHeaders = {} }) => ({
        headers: {
          ...ctxHeaders,
          ...(operation.getContext().clientName === 'magento' ? {} : headers),
        },
      }));
      return forward(operation);
    });

    const appLink = ApolloLink.split(
      (operation) => operation.getContext().clientName === 'magento',
      magentoLink,
      splitLink
    );

    return new ApolloClient({
      uri,
      cache: new InMemoryCache(),
      link: concat(authMiddleware, appLink),
    });
  }, [token]);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default App;
