import React, { useState, useCallback, useEffect } from 'react';
import { Segment, Button } from 'components/ui';
import {
  HiOutlineDesktopComputer,
  HiOutlineDeviceMobile,
  HiOutlineDeviceTablet,
  HiOutlineRefresh,
} from 'react-icons/hi';
import { useProjects } from 'contexts/Projects';

const Preview = (props) => {
  const { title, pathname } = props;
  const {
    saveButtonLoading,
    localChanges,
    device,
    setDevice,
    currentProject,
    parentProject,
  } = useProjects();

  if (!currentProject) {
    return null;
  }

  const { settings } = currentProject || {};
  const { store_view_code, type } = settings || {};
  const { domain_staging_url, use_store_code_in_url } =
    parentProject?.settings || currentProject?.settings;
  const domain = domain_staging_url || domain_staging_url;
  const suffix = use_store_code_in_url ? store_view_code : null;
  // const domain = 'https://pwa-project-boilerplate-xaubt.local.pwadev:8784';
  const previewUrl = `${domain}${suffix ? `/${suffix}` : ''}`;

  const handleClickDisplay = useCallback(
    (val) => {
      if (Array.isArray(val) && val.length > 0) {
        setDevice(val[0]);
      } else {
        setDevice(val);
      }
    },
    [setDevice]
  );

  const handleReload = useCallback(() => {
    const iframe = document.querySelector('iframe');
    if (iframe) {
      iframe.src = iframe.src;
    }
  }, []);

  useEffect(() => {
    const frame = document.querySelector('iframe');
    if (frame) {
      frame.contentWindow.postMessage(JSON.stringify(localChanges), '*');
    }
  }, [localChanges]);

  return (
    <div className="flex flex-col gap-4 h-full">
      <div className="flex justify-between lg:grid grid-cols-3 lg:items-center">
        <h3>{title}</h3>
        <div className="flex md:justify-center">
          <Segment value={[device]} onChange={(val) => handleClickDisplay(val)}>
            <Segment.Item value="mobile">
              <HiOutlineDeviceMobile size={22} />
            </Segment.Item>
            <Segment.Item value="desktop">
              <HiOutlineDesktopComputer size={22} />
            </Segment.Item>
          </Segment>
        </div>
        <div className="flex justify-end gap-4">
          <Button onClick={handleReload} variant="default">
            <HiOutlineRefresh size={16} />
          </Button>

          <Button
            form="active_form"
            variant="solid"
            type="submit"
            loading={saveButtonLoading}
          >
            Publish
          </Button>
        </div>
      </div>
      <div className="flex justify-center h-full">
        <iframe
          class={`border-2 border-solid border-gray-300 ${
            device.includes('desktop') ? 'w-full h-full' : 'w-[390px] h-full'
          }`}
          src={`${previewUrl}${pathname}`}
          frameBorder="0"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
};

export default Preview;
