import React, { useState } from 'react';
import { Input, Dialog } from 'components/ui';
import { GooglePicker } from '@hello-pangea/color-picker';

const ColorInput = ({ ...props }) => {
  const [active, setActive] = useState(false);

  const handleOpen = () => {
    setActive(true);
  };

  const handleClose = () => {
    setActive(false);
  };

  return (
    <>
      <Input
        {...props}
        suffix={
          <button
            onClick={handleOpen}
            type="button"
            className="w-6 h-6 rounded border border-1 border-gray-300 cursor-pointer"
            style={{ backgroundColor: props?.field?.value }}
          ></button>
        }
      />

      <Dialog
        width={700}
        isOpen={active}
        onClose={handleClose}
        onRequestClose={handleClose}
      >
        <h5 className="mb-4">{props?.placeholder}</h5>
        <GooglePicker
          color={props?.field?.value}
          onChange={(value) => props?.form?.setFieldValue(props?.field?.name, value.hex)}
          header={'Select color'}
        />
      </Dialog>
    </>
  );
};

export default ColorInput;
